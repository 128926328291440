import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Button, Select, Modal, Input, Tabs, Spin, notification, Form } from 'antd';
import './InventoryPage.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import InventoryPage from './InventoryPage';
import InventoryReport from './InventoryReport';
import CoursePage from './CoursePage';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TabPane } = Tabs;

const CurrentInventory = () => {
    const { t } = useTranslation();
    const [inventory, setInventory] = useState([]);
    const [filteredInventory, setFilteredInventory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('current');
    const [adjustments, setAdjustments] = useState({});
    const [selectedInventoryId, setSelectedInventoryId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editForm] = Form.useForm();
    const [currentItem, setCurrentItem] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const categories = [
        { key: 'All', label: t('all') },
        { key: 'Course', label: t('course') },
        { key: 'Book', label: t('Book') },
        { key: 'Uniform', label: t('Uniform') },
        { key: 'Exam Uniform', label: t('Exam Uniform') },
        { key: 'Certificate', label: t('Certificates') },
        { key: 'Others', label: t('others') }
    ];

    const API_URL = process.env.REACT_APP_API_URL;

    const fetchInventory = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/inventory`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const currentQuantityResponse = await axios.get(`${API_URL}/inventory/current-quantity`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const inventoryWithQuantities = response.data.map((item) => {
                const quantityData = currentQuantityResponse.data.find(q => q.inventory_id === item.inventory_id);
                return {
                    ...item,
                    current_quantity: quantityData ? quantityData.current_quantity : 0
                };
            });

            setInventory(inventoryWithQuantities);
            setFilteredInventory(inventoryWithQuantities);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch inventory data.');
            setLoading(false);
        }
    }, [API_URL]);

    useEffect(() => {
        fetchInventory();
    }, [fetchInventory]);

    const handleTabChange = (key) => {
        setActiveTab(key);
        // Perform any data fetching or reset logic needed when the tab changes
        if (key === 'current') fetchInventory();
        // You can add similar functions for other tabs if needed
    };

    const handleCategoryFilter = (category) => {
        setSelectedCategory(category);
        if (category === 'All') {
            setFilteredInventory(inventory);
        } else {
            const filteredData = inventory.filter(item => item.category === category);
            setFilteredInventory(filteredData);
        }
    };

    const handleQuantityChange = (value) => {
        setAdjustments((prevAdjustments) => ({
            ...prevAdjustments,
            [selectedInventoryId]: value
        }));
    };

    const handleUpdateQuantity = async () => {
        const quantity_change = adjustments[selectedInventoryId];
        if (!quantity_change) {
            notification.error({
                message: 'Error',
                description: 'Please enter a quantity to adjust.',
            });
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await axios.post(`${API_URL}/inventory/log`, {
                inventory_id: selectedInventoryId,
                quantity_change: parseInt(quantity_change, 10),
                change_reason: 'Admin'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setIsModalOpen(false);
            fetchInventory();
            notification.success({
                message: 'Success',
                description: 'Inventory quantity updated successfully!',
            });
        } catch (error) {
            console.error('Error adjusting quantity:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update inventory quantity.',
            });
        }
    };

    const openModal = (id) => {
        setSelectedInventoryId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedInventoryId(null);
        setIsModalOpen(false);
    };

    const openEditModal = (item) => {
        setCurrentItem(item);
        editForm.setFieldsValue(item);
        setIsEditModalOpen(true);
    };

    const handleEditSubmit = async () => {
        try {
            const updatedItem = editForm.getFieldsValue();
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/inventory/${currentItem.inventory_id}`, updatedItem, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            notification.success({
                message: 'Success',
                description: 'Inventory item updated successfully!',
            });
            setIsEditModalOpen(false);
            fetchInventory();
        } catch (error) {
            console.error('Error updating inventory item:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update inventory item.',
            });
        }
    };

    const handleStatusChange = async (id, newStatus) => {
        Modal.confirm({
            title: 'Confirm to submit',
            content: `Are you sure you want to change the status to ${newStatus}?`,
            onOk: async () => {
                try {
                    const token = localStorage.getItem('token');
                    await axios.put(`${API_URL}/inventory/${id}/status`, { status: newStatus }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    fetchInventory();
                    notification.success({
                        message: 'Success',
                        description: 'Status changed successfully!',
                    });
                } catch (error) {
                    console.error('Error changing status:', error);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to change status.',
                    });
                }
            },
            onCancel() {
            },
        });
    };

    if (loading) return <Spin tip="Loading..." />;
    if (error) return <p>{error}</p>;

    return (
        <div className="current-inventory-container">
            <Sidebar />
            <Header />
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab={t('Current Inventory')} key="current">
                    <div className="category-buttons">
                        {categories.map(category => (
                            <Button 
                                key={category.key}
                                type={selectedCategory === category.key ? 'primary' : 'default'}
                                onClick={() => handleCategoryFilter(category.key)}
                            >
                                {t(category.label)}
                            </Button>
                        ))}
                    </div>
                    <Table
                        dataSource={filteredInventory}
                        columns={[
                            {
                                title: t('Product ID'),
                                dataIndex: 'product_id',
                                key: 'product_id',
                            },
                            {
                                title: t('name'),
                                dataIndex: 'item_name',
                                key: 'item_name',
                                render: (text) => <div className="wrap-text">{text}</div>,
                            },
                            {
                                title: t('description'),
                                dataIndex: 'item_description',
                                key: 'item_description',
                                render: (text) => <div className="wrap-text">{text}</div>,
                            },
                            {
                                title: t('type'),
                                dataIndex: 'category',
                                key: 'category',
                                render: (category) => categories.find(c => c.key === category)?.label || category
                            },
                            {
                                title: t('price'),
                                dataIndex: 'price_per_unit',
                                key: 'price_per_unit',
                                render: (price) => new Intl.NumberFormat('en-US', { 
                                    style: 'decimal', 
                                    minimumFractionDigits: 2, 
                                    maximumFractionDigits: 2 
                                }).format(price),
                            },
                            {
                                title: t('quantity'),
                                dataIndex: 'current_quantity',
                                key: 'current_quantity',
                            },
                            {
                                title: t('status'),
                                dataIndex: 'status',
                                key: 'status',
                                render: (status, item) => (
                                    <Select
                                        value={status}
                                        onChange={(newStatus) => handleStatusChange(item.inventory_id, newStatus)}
                                        className={status === 'active' ? 'select-active' : 'select-inactive'}
                                    >
                                        <Option value="active">{t('active')}</Option>
                                        <Option value="inactive">{t('inactive')}</Option>
                                    </Select>
                                ),
                            },
                            {
                                title: t('adjust'),
                                key: 'adjust',
                                render: (_, item) => (
                                    <Button onClick={() => openModal(item.inventory_id)}>{t('Adjust Inventory')}</Button>
                                ),
                            },
                            {
                                title: t('edit'),
                                key: 'edit',
                                render: (_, item) => (
                                    <Button onClick={() => openEditModal(item)}>{t('edit')}</Button>
                                ),
                            },
                        ]}
                        rowKey="inventory_id"
                        scroll={{ x: true }}
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </TabPane>
                <TabPane tab={t('addcourse')} key="course">
                    <CoursePage key={activeTab} />
                </TabPane>
                <TabPane tab={t('Add Inventory')} key="add">
                    <InventoryPage key={activeTab} />
                </TabPane>
                <TabPane tab={t('Inventory Report')} key="report">
                    <InventoryReport key={activeTab} />
                </TabPane>
            </Tabs>

            <Modal
                title={t('Adjust Inventory Quantity')}
                visible={isModalOpen}
                onOk={handleUpdateQuantity}
                onCancel={closeModal}
            >
                <Input
                    value={adjustments[selectedInventoryId] || ''}
                    onChange={(e) => handleQuantityChange(e.target.value)}
                    placeholder={t('Enter quantity')}
                />
            </Modal>

            <Modal
                title={t('edit_inventory_item')}
                visible={isEditModalOpen}
                onOk={handleEditSubmit}
                onCancel={() => setIsEditModalOpen(false)}
            >
                <Form form={editForm} layout="vertical">
                    <Form.Item name="product_id" label={t('Product ID')}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="item_name" label={t('Item Name')}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="item_description" label={t('description')}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="category" label={t('category')}>
                        <Select>
                            {categories.filter(category => category.key !== 'All').map(category => (
                                <Option key={category.key} value={category.key}>
                                    {category.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="price_per_unit" label={t('Price per Unit')}>
                        <Input/> 
                    </Form.Item>
                    <Form.Item name="status" label={t('status')}>
                        <Select>
                            <Option value="active">{t('active')}</Option>
                            <Option value="inactive">{t('inactive')}</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default withRoleAccess(CurrentInventory, ['superadmin']);
