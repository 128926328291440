import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/th';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditStudentModal = ({ isOpen, onClose, onSave, studentId }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstnamethai: '',
        lastnamethai: '',
        firstnameenglish: '',
        lastnameenglish: '',
        nickname: '',
        nationalid: '',
        phone: '',
        address: '',
        email: '',
        date_of_birth: null,
        height: '',
        weight: '',
        race: 'ไทย',
        religion: '',
        nationality: 'ไทย',
        emergency_phone_number: '',
        education: '',
        massage_education: '',
        medicalhistory: '',
        application_fee: '',
        branch: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (studentId) {
            const fetchStudent = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${API_URL}/students/${studentId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setFormData({
                        ...response.data,
                        race: response.data.race || 'ไทย',
                        nationality: response.data.nationality || 'ไทย',
                        date_of_birth: response.data.date_of_birth ? new Date(response.data.date_of_birth) : null,
                    });
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching student:', error);
                    setError(t('fetchStudentError'));
                    setLoading(false);
                }
            };

            fetchStudent();
        }
    }, [studentId, API_URL, t]);

    const educationOptions = [
        { value: 'below_highschool', label: t('below_highschool') },
        { value: 'highschool', label: t('highschool') },
        { value: 'below_bachelor_degree', label: t('below_bachelor_degree') },
        { value: 'bachelor_degree', label: t('bachelor_degree') },
        { value: 'above_bachelor_degree', label: t('above_bachelor_degree') },
    ];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        setFormData(prevData => ({
            ...prevData,
            date_of_birth: date
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const dataToSend = {
                ...formData,
                date_of_birth: formData.date_of_birth ? moment(formData.date_of_birth).format('YYYY-MM-DD') : null,
            };
            await axios.put(`${API_URL}/students/${studentId}`, dataToSend, {
                headers: { Authorization: `Bearer ${token}` }
            });
            onSave(dataToSend);
            onClose();
        } catch (error) {
            console.error('Error updating student:', error);
            setError(t('updateStudentError'));
        }
    };

    const renderCustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
        const months = [
            "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
            "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
        ];

        return (
            <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                </button>
                <select
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option + 543}
                        </option>
                    ))}
                </select>
                <select
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                </button>
            </div>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="panel-overlay">
            <div className="modal-content">
                <span className="modal-close-icon" onClick={onClose}>&times;</span>
                <h2>{t('editStudent')}</h2>
                {loading ? (
                    <p>{t('loading')}</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <form onSubmit={handleSubmit} className="two-column-form">
                        <div className="form-column">
                            <div className="form-group">
                                <label>{t('firstNameThai')}:</label>
                                <input type="text" name="firstnamethai" value={formData.firstnamethai} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('lastNameThai')}:</label>
                                <input type="text" name="lastnamethai" value={formData.lastnamethai} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('firstNameEnglish')}:</label>
                                <input type="text" name="firstnameenglish" value={formData.firstnameenglish} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('lastNameEnglish')}:</label>
                                <input type="text" name="lastnameenglish" value={formData.lastnameenglish} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nickname')}:</label>
                                <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nationalID')}:</label>
                                <input type="text" name="nationalid" value={formData.nationalid} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                            <label>{t('dateOfBirth')}:</label>
                            <DatePicker
                                selected={formData.date_of_birth}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                renderCustomHeader={renderCustomHeader}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                placeholderText={t('selectDateOfBirth')}
                            />
                        </div>
                            <div className="form-group">
                                <label>{t('phone')}:</label>
                                <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('email')}:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-column">
                            <div className="form-group">
                                <label>{t('address')}:</label>
                                <input type="text" name="address" value={formData.address} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('emergencyPhoneNumber')}:</label>
                                <input type="text" name="emergency_phone_number" value={formData.emergency_phone_number} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('height')} (cm):</label>
                                <input type="number" name="height" value={formData.height} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('weight')} (kg):</label>
                                <input type="number" name="weight" value={formData.weight} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('race')}:</label>
                                <input type="text" name="race" value={formData.race} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('religion')}:</label>
                                <select name="religion" value={formData.religion} onChange={handleChange}>
                                    <option value="">{t('selectReligion')}</option>
                                    <option value="พุทธ">พุทธ</option>
                                    <option value="คริสต์">คริสต์</option>
                                    <option value="อิสลาม">อิสลาม</option>
                                    <option value="คาทอลิก">คาทอลิก</option>
                                    <option value="อื่นๆ">อื่นๆ</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{t('nationality')}:</label>
                                <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('branch')}:</label>
                                <select name="branch" value={formData.branch} onChange={handleChange}>
                                    <option value="">{t('selectBranch')}</option>
                                    <option value="Bangkok">Bangkok</option>
                                    <option value="Pattaya">Pattaya</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-footer">
                            <div className="form-group">
                            <label>{t('education')}:</label>
                            <select 
                                name="education" 
                                value={formData.education} 
                                onChange={handleChange}
                                required
                            >
                                <option value="">{t('selectEducation')}</option>
                                {educationOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            </div>
                            <div className="form-group">
                                <label>{t('massageEducation')}:</label>
                                <input type="text" name="massage_education" value={formData.massage_education} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('medicalHistory')}:</label>
                                <textarea name="medicalhistory" value={formData.medicalhistory} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('applicationFee')}:</label>
                                <input type="text" name="application_fee" value={formData.application_fee} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="modal-actions">
                            <button type="button" className="cancel-button" onClick={onClose}>{t('cancel')}</button>
                            <button type="submit">{t('save')}</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default withRoleAccess(EditStudentModal, ['admin', 'superadmin']);