import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Spin, notification, Tooltip, Row, Col, Card, Statistic } from 'antd';
import moment from 'moment';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Sales.css';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import InvoiceModal from '../Payment/InvoiceModal';

const SalesReport = () => {
  const { t, i18n } = useTranslation();
  const [salesTransactions, setSalesTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]); // State for filtered data
  const [dateRange, setDateRange] = useState([null, null]); // State for date range
  const [itemNameSearch, setItemNameSearch] = useState(''); 
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [deletingTransactionId, setDeletingTransactionId] = useState(null); 
  const [salesSummary, setSalesSummary] = useState({
    yearToDate: 0,
    monthToDate: 0,
    today: 0
  });
  const API_URL = process.env.REACT_APP_API_URL;

  // Function to format dates
  const formatDateTime = (dateString) => {
    const date = moment(dateString).toDate(); 
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; 
    }
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, 
    };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };

  // Function to format prices
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  const formatTotalPrice = (price) => {
    if (price === undefined || price === null || isNaN(price)) {
      return '0.00';
    }
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  const calculateSalesSummary = (transactions) => {
    const now = moment();
    const startOfYear = moment().startOf('year');
    const startOfMonth = moment().startOf('month');
    const startOfDay = moment().startOf('day');

    const summary = transactions.reduce((acc, transaction) => {
      const transactionDate = moment(transaction.transaction_date);
      const amount = parseFloat(transaction.final_amount);

      if (transactionDate.isSameOrAfter(startOfYear)) {
        acc.yearToDate += amount;
      }
      if (transactionDate.isSameOrAfter(startOfMonth)) {
        acc.monthToDate += amount;
      }
      if (transactionDate.isSameOrAfter(startOfDay)) {
        acc.today += amount;
      }

      return acc;
    }, { yearToDate: 0, monthToDate: 0, today: 0 });

    setSalesSummary(summary);
  };

  // Fetch sales transactions
  const fetchSalesTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/sales/sales-transactions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSalesTransactions(response.data);
      setFilteredTransactions(response.data);
      calculateSalesSummary(response.data);
    } catch (error) {
      console.error('Error fetching sales transactions:', error);
      notification.error({
        message: t('error'),
        description: t('fetch_error'),
      });
    }
    setLoading(false);
  }, [API_URL, t]);

  useEffect(() => {
    fetchSalesTransactions();
  }, [fetchSalesTransactions]);

  useEffect(() => {
    fetchSalesTransactions();
  }, [fetchSalesTransactions]);

  // Date range filter handler
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    const [start, end] = dates || [];
    if (!start || !end) {
      setFilteredTransactions(salesTransactions); // Reset to all data if no date is selected
      return;
    }

    const filtered = salesTransactions.filter(transaction => {
      const transactionDate = moment(transaction.transaction_date);
      return transactionDate.isBetween(moment(start).startOf('day'), moment(end).endOf('day'));
    });

    setFilteredTransactions(filtered); // Update filtered transactions based on date range
  };

  const handleSearch = (value, type) => {
    const searchValue = value.toLowerCase();
    const filtered = salesTransactions.filter(transaction => {
      if (type === 'nameOrId') {
        return (
          (transaction.student_name && transaction.student_name.toLowerCase().includes(searchValue)) ||
          (transaction.nationalid && transaction.nationalid.toString().toLowerCase().includes(searchValue))
        );
      } else if (type === 'documentNo') {
        return transaction.document_no && transaction.document_no.toLowerCase().includes(searchValue);
      } else if (type === 'transactionId') {
        const transactionIdString = transaction.transaction_id ? transaction.transaction_id.toString().toLowerCase() : '';
        return transactionIdString.includes(searchValue);
      } else if (type === 'itemName') { // New search type for item name
        return transaction.items && transaction.items.some(item => item.item_name.toLowerCase().includes(searchValue));
      }
      return false;
    });

    setFilteredTransactions(filtered);
  };

  const showDeleteModal = (transactionId) => {
    setDeletingTransactionId(transactionId);
    setPasswordModalVisible(true);
  };
  
  const handleDeleteTransaction = async () => {
    if (!password) {
      notification.error({ message: t('error'), description: t('password_required') });
      return;
    }
  
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/sales/delete-sale/${deletingTransactionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { password }, // Send the password in the request body
      });
  
  
      notification.success({
        message: t('success'),
        description: t('sale_deleted_successfully'),
      });
      fetchSalesTransactions(); // Refresh the sales transactions list
    } catch (error) {
      console.error('Error deleting sale transaction:', error.response || error); // Log the error and response (if any)
      notification.error({
        message: t('error'),
        description: t('delete_sale_error'),
      });
    } finally {
      setLoading(false);
      setPasswordModalVisible(false); // Close the modal
    }
  };
  
  const showInvoiceModal = (transaction) => {
    setSelectedInvoice(transaction);
    setIsInvoiceModalVisible(true);
  };
  
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalVisible(false);
    setSelectedInvoice(null);
  };
// CSV Export data
const csvData = filteredTransactions.map((transaction) => {
  let translatedPaymentMethod = transaction.payment_method;

  // Translate the payment method for only 'fullpay', 'paymentplan', and 'scholarship'
  switch (transaction.payment_method) {
    case 'fullpay':
      translatedPaymentMethod = t('fullpay');
      break;
    case 'paymentplan':
      translatedPaymentMethod = t('paymentplan');
      break;
    case 'scholarship':
      translatedPaymentMethod = t('scholarship');
      break;
    default:
      translatedPaymentMethod = transaction.payment_method; // Use the original value if it's not one of the three
  }

  return {
    transaction_id: transaction.document_no,
    student_id: transaction.schoolid,
    student_name: transaction.student_name,
    student_nationalid: transaction.nationalid,
    items: transaction.items ? transaction.items.map(item => item.item_name).join(', ') : t('no_items'),
    total_amount: `${parseFloat(transaction.total_amount).toFixed(2)}`,
    final_amount: `${parseFloat(transaction.final_amount).toFixed(2)}`,
    transaction_date: formatDateTime(transaction.transaction_date),
    payment_method: translatedPaymentMethod, // Use the translated payment method
    created_by: transaction.created_by,
  };
});


  const showTransactionDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setModalVisible(true);
  };

  const columns = [
    {
      title: t('transaction_id'),
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
      render: (text, record) => (
        <a onClick={() => showInvoiceModal(record)}>{text}</a>
      ),
    },
    {
      title: t('student_id'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student_name'),
      dataIndex: 'student_name',
      key: 'student_name',
      ellipsis: true,
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('items'),
      dataIndex: 'items',
      key: 'items',
      render: (items) => {
        if (!items || items.length === 0) {
          return t('no_items');
        }
        
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {items.map((item, index) => (
              <Tooltip key={index} title={item.item_name}>
                <div
                  style={{
                    padding: '4px 0',
                    borderBottom: index < items.length - 1 ? '1px solid #f0f0f0' : 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {item.item_name}
                </div>
              </Tooltip>
            ))}
          </div>
        );
      },
    },    
    {
      title: t('total_amount'),
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (amount) => formatPrice(amount),
    },
    {
      title: t('final_amount'),
      dataIndex: 'final_amount',
      key: 'final_amount',
      render: (amount) => formatPrice(amount),
    },
    {
      title: t('transaction_date'),
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      ellipsis: true,
      render: (date) => (
        <div className="wrap-text">
          {formatDateTime(date)}
        </div>
      ),
    },
    {
      title: t('payment_method'),
      dataIndex: 'payment_method',
      key: 'payment_method',
      ellipsis: true,
      render: (payment_method) => {
        if (payment_method === 'paymentplan') {
          return t('installment');
        } else if (payment_method === 'fullpay') {
          return t('fullpay');
        } else if (payment_method === 'scholarship') {
          return t('scholarship');
        } else {
          return payment_method;
        }
      },
    },
    {
      title: t('created_by'),
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: t('details'),
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => showTransactionDetails(record)}>{t('view_details')}</Button>
      ),
    },
    {
      title: t('delete'),
      key: 'delete',
      render: (_, record) => (
        <Button danger onClick={() => showDeleteModal(record.transaction_id)}>
          {t('delete')}
        </Button>
      ),
    },
  ];

  return (
    <div className="pos-system">
      <Sidebar />
      <Header />
      <h1>{t('sales_report')}</h1>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#E6F7FF', borderColor: '#1890FF' }}>
            <Statistic
              title={t('year_to_date_sales')}
              value={salesSummary.yearToDate}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#0050B3' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#F6FFED', borderColor: '#52C41A' }}>
            <Statistic
              title={t('month_to_date_sales')}
              value={salesSummary.monthToDate}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#389E0D' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#FFF7E6', borderColor: '#FA8C16' }}>
            <Statistic
              title={t('today_sales')}
              value={salesSummary.today}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#D46B08' }}
            />
          </Card>
        </Col>
      </Row>
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        
        <DatePicker
          className="ant-datepicker"
          selected={dateRange[0]}
          onChange={handleDateRangeChange}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          selectsRange
          dateFormat="dd-MM-yyyy"
          isClearable
          placeholderText={t('Select Date Range')}
        />
        {/* Search by Student Name or National ID */}
        <Input.Search
          placeholder={t('searchByNameOrNationalID')}
          onSearch={(value) => handleSearch(value, 'nameOrId')}
          enterButton
          style={{ maxWidth: '300px' }}
        />
        <Input.Search
          placeholder={t('searchByDocumentNo')}
          onSearch={(value) => handleSearch(value, 'documentNo')}
          enterButton
          style={{ maxWidth: '300px' }}
        />
        <Input.Search
          placeholder={t('searchByTransactionID')}
          onSearch={(value) => handleSearch(value, 'transactionId')}
          enterButton
          style={{ maxWidth: '300px' }}
        />

        </div>

        <div style={{ marginBottom: '20px' }}>
        <Input.Search
          placeholder={t('ค้นหาจากชื่อสินค้า')} // New search input for item name
          onSearch={(value) => handleSearch(value, 'itemName')}
          enterButton
          style={{ maxWidth: '300px' }}
        />
          <CSVLink
            data={csvData}
            filename="sales_report.csv"
            className="ant-btn ant-btn-default"
          >
            <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
          </CSVLink>
        </div>
      {/* Render Table with Filtered Data */}
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={columns}
          dataSource={filteredTransactions} // Use filteredTransactions here
          rowKey="transaction_id"
          scroll={{ x: true }}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      )}

<Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >

        <h2>{t('transaction_details')}</h2> 
        {selectedTransaction && (
          <div>
            <p><strong>{t('document_no')}:</strong> {selectedTransaction.document_no}</p>
            <p><strong>{t('student_id')}:</strong> {selectedTransaction.schoolid}</p>
            <p><strong>{t('student_name')}:</strong> {selectedTransaction.student_name}</p>
            <p><strong>{t('total_amount')}:</strong> {formatPrice(selectedTransaction.total_amount)}</p>
            <p><strong>{t('discount')}:</strong> {formatPrice(selectedTransaction.discount)}</p>
            <p><strong>{t('vat')}:</strong> {formatPrice(selectedTransaction.vat)}</p>
            <p><strong>{t('final_amount')}:</strong> {formatPrice(selectedTransaction.final_amount)}</p>
            <p><strong>{t('transaction_date')}:</strong> {moment(selectedTransaction.transaction_date).format('YYYY-MM-DD HH:mm')}</p>
            <p>
              <strong>{t('payment_method')}:</strong> 
              {selectedTransaction.payment_method === 'installment' && t('installment')}
              {selectedTransaction.payment_method === 'fullpay' && t('fullpay')}
              {selectedTransaction.payment_method === 'scholarship' && t('scholarship')}
            </p>
            <p><strong>{t('created_by')}:</strong> {selectedTransaction.created_by}</p>
            <h3>{t('items_purchased')}</h3>
            <Table
              columns={[
                { title: t('item'), dataIndex: 'item_name', key: 'item_name' },
                { 
                  title: t('price'), 
                  dataIndex: 'price', 
                  key: 'price', 
                  render: (price) => {

                    return formatTotalPrice(parseFloat(price) || 0);
                  }
                },
                { 
                  title: t('quantity'), 
                  dataIndex: 'quantity', 
                  key: 'quantity', 
                  render: (quantity) => {
                    return quantity || 1; // Default to 1 if no quantity is provided
                  }
                },
                { 
                  title: t('total'), 
                  key: 'total_amount', 
                  render: (_, item) => {
                    const total = item.price * item.quantity; // Calculate total based on quantity
                    return formatTotalPrice(total);
                  }
                }
              ]}
              dataSource={selectedTransaction.items}
              scroll={{ x: true }}
              pagination={{
                pageSize: 50,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />

          </div>
        )}
      </Modal>

      <Modal
          title={t('delete_transaction')}
          visible={passwordModalVisible}
          onCancel={() => setPasswordModalVisible(false)}
          onOk={handleDeleteTransaction} // Call the delete function when confirmed
        >
          <Form layout="vertical">
            <Form.Item label={t('enterPasswordToDelete')} required>
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('password')}
              />
            </Form.Item>
          </Form>
        </Modal>
        <InvoiceModal
          show={isInvoiceModalVisible}
          onClose={handleInvoiceModalClose}
          registration={selectedInvoice}
        />
    </div>
  );
};

export default withRoleAccess(SalesReport, ['superadmin', 'admin']);
