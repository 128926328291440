import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Modal, Input, Form, Select, notification, Tabs, Tooltip } from 'antd';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import SignaturePad from 'react-signature-canvas';
import 'moment/locale/th';
import PaymentReportPage from './PaymentReportPage';
import PaymentSummaryPage from './PaymentSummaryPage';
import ScholarshipPayment from './ScholarshipPayment';
import PaymentPlanPayment from './PaymentPlanPayment';
import InvoiceModal from './InvoiceModal';
import DatePicker from 'react-datepicker';
import { registerLocale } from  "react-datepicker";
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { CSVLink } from 'react-csv';

const { TabPane } = Tabs;
const { Option } = Select;

registerLocale('th', th);
registerLocale('en', en);

const formatPrice = (price) => {
  const formattedPrice = Number(price);
  return isNaN(formattedPrice)
    ? '0.00'
    : new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedPrice);
};

// Modal Confirmation for NPL
const NPLConfirmationModal = ({ visible, transaction, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('confirm_npl')}
      visible={visible}
      onOk={() => onConfirm(transaction)}
      onCancel={onCancel}
      okText={t('confirm')}
      cancelText={t('cancel')}
    >
      <p><strong>{t('transaction_id')}:</strong> {transaction?.transaction_id}</p>
      <p><strong>{t('student_name')}:</strong> {transaction?.student_name}</p>
      <p><strong>{t('remaining_balance')}:</strong> {formatPrice(transaction?.remaining_amount || 0)}</p>
      <p>{t('confirm_npl_message')}</p>
    </Modal>
  );
};

const PaymentPage = () => {
  const { t, i18n } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isNPLModalOpen, setIsNPLModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [fileError, setFileError] = useState(null); // State to track file errors
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: '',
    searchTerm: '',
    documentNo: '',
    paymentMethod: '',
    nplStatus: ''
  });
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_API_URL;
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [activeKey, setActiveKey] = useState('1');
  const [shouldRefreshTabs, setShouldRefreshTabs] = useState({
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false
  });


  const handleTabChange = (key) => {
    setActiveKey(key);
    
    // Refresh data when switching tabs
    switch(key) {
      case '1':
        fetchTransactions();
        break;
      case '2':
        // Trigger PaymentReportPage refresh
        setShouldRefreshTabs(prev => ({ ...prev, '2': true }));
        break;
      case '3':
        // Trigger PaymentSummaryPage refresh
        setShouldRefreshTabs(prev => ({ ...prev, '3': true }));
        break;
      case '4':
        // Trigger ScholarshipPayment refresh
        setShouldRefreshTabs(prev => ({ ...prev, '4': true }));
        break;
      case '5':
        // Trigger PaymentPlanPayment refresh
        setShouldRefreshTabs(prev => ({ ...prev, '5': true }));
        break;
      default:
        break;
    }
  };

// Function to fetch transactions
const fetchTransactions = useCallback(async () => {
  setLoading(true);
  try {
    const token = localStorage.getItem('token');

    const response = await axios.get(`${API_URL}/sales/sales-transactions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const paymentResponse = await axios.get(`${API_URL}/payment/reports`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const payments = paymentResponse.data;


    const transactionsWithBalance = response.data.map((transaction) => {
      const matchingPayment = payments.find(
        (payment) => payment.transaction_id === transaction.transaction_id
      );

      return {
        ...transaction,
        remaining_amount: matchingPayment
          ? matchingPayment.remaining_amount
          : transaction.final_amount,
        npl: transaction.npl || false, 
      };
    });


    const filteredTransactions = transactionsWithBalance.filter(
      (transaction) => parseFloat(transaction.remaining_amount) > 0
    );

    setTransactions(filteredTransactions);
    setLoading(false);

  } catch (error) {
    console.error('Error fetching transactions:', error);
    setLoading(false);
  }
}, [API_URL]);



useEffect(() => {
  fetchTransactions();
}, [fetchTransactions]);

const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilters(prevFilters => ({
    ...prevFilters,
    [name]: value
  }));
};
const handleDateChange = (date, fieldName) => {
  setFilters(prevFilters => ({
    ...prevFilters,
    [fieldName]: date ? moment(date).toDate() : null
  }));
};
const applyFilters = useCallback(() => {
  let filtered = transactions;

  if (filters.fromDate) {
    const startDate = moment(filters.fromDate).startOf('day');
    filtered = filtered.filter(transaction => 
      moment(transaction.transaction_date).isSameOrAfter(startDate)
    );
  }

  if (filters.toDate) {
    const endDate = moment(filters.toDate).endOf('day');
    filtered = filtered.filter(transaction => 
      moment(transaction.transaction_date).isSameOrBefore(endDate)
    );
  }

  if (filters.searchTerm) {
    const searchTerm = filters.searchTerm.toLowerCase();
    filtered = filtered.filter(transaction => 
      transaction.student_name.toLowerCase().includes(searchTerm) ||
      (transaction.nationalid && transaction.nationalid.toString().toLowerCase().includes(searchTerm))
    );
  }

  if (filters.documentNo) {
    const documentNo = filters.documentNo.toLowerCase();
    filtered = filtered.filter(transaction => 
      transaction.document_no.toLowerCase().includes(documentNo)
    );
  }

  if (filters.paymentMethod) {
    filtered = filtered.filter(transaction => transaction.payment_method === filters.paymentMethod);
  }

  if (filters.nplStatus) {
    filtered = filtered.filter(transaction => 
      (filters.nplStatus === 'NPL' && transaction.npl) || 
      (filters.nplStatus === 'non-NPL' && !transaction.npl)
    );
  }

  setFilteredTransactions(filtered);
}, [transactions, filters]);

useEffect(() => {
  applyFilters();
}, [applyFilters]);


  // Function to handle file selection and validation
const handleFileChange = (event) => {
  const file = event.target.files[0];

  // Reset any previous errors
  setFileError(null);

  // Validate file size (not more than 10MB)
  if (file && file.size > 10 * 1024 * 1024) {
    setFileError(t('file_too_large')); // Custom error message for large file
    return;
  }

  // Validate file type (only PDF or JPEG)
  if (file && !['application/pdf', 'image/jpeg'].includes(file.type)) {
    setFileError(t('invalid_file_type')); // Custom error message for invalid type
    return;
  }

  // If valid, set the file to the state
  setSelectedFile(file);
};

  // Proceed with the form submission and upload logic
  const formData = new FormData();
  formData.append('payment_file', selectedFile);

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const openInvoiceModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsInvoiceModalOpen(true);
  };

  const clearModalForm = () => {
    form.resetFields(); // Clear form fields
    setSelectedFile(null); // Clear selected file state
    setFileError(null); // Clear any file errors
    
    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    
    // Clear signature pad
    if (signaturePadRef.current) {
      signaturePadRef.current?.clear();
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      clearModalForm();
    }
  }, [isModalOpen]);

  const handleTabRefresh = (tabKey) => {
    // Set loading states if needed
    setLoading(true);
  
    switch(tabKey) {
      case '2':
        // Instead of directly calling undefined functions, trigger a refresh through state
        setShouldRefreshTabs(prev => ({
          ...prev,
          '2': !prev['2'] // Toggle the state to force refresh
        }));
        break;
      case '3':
        setShouldRefreshTabs(prev => ({
          ...prev,
          '3': !prev['3']
        }));
        break;
      case '4':
        setShouldRefreshTabs(prev => ({
          ...prev,
          '4': !prev['4']
        }));
        break;
      case '5':
        setShouldRefreshTabs(prev => ({
          ...prev,
          '5': !prev['5']
        }));
        break;
      default:
        break;
    }
  };

  const handleModalOk = () => {
    Modal.confirm({
      title: t('Confirm Payment'),
      content: t('คุณแน่ใจว่าต้องการคอนเฟิร์มการชำระเงินนี้?'),
      onOk: async () => {
        try {
          const values = form.getFieldsValue();
          const paymentAmount = parseFloat(values.payment_amount);
          const remainingAmount = selectedTransaction.final_amount - paymentAmount;
  
          const signatureSvg = signaturePadRef.current ? signaturePadRef.current.toDataURL('image/svg+xml') : '';
  
          const formData = new FormData();
          formData.append('transaction_id', selectedTransaction.transaction_id);
          formData.append('student_id', selectedTransaction.student_id);
          formData.append('schoolid', selectedTransaction.schoolid);
          formData.append('student_name', selectedTransaction.student_name);
          formData.append('document_no', selectedTransaction.document_no);
          formData.append('payment_amount', paymentAmount);
          formData.append('remaining_amount', remainingAmount);
          formData.append('payment_method', values.payment_method);
          formData.append('memo', values.memo);
          formData.append('signature', signatureSvg);
  
          if (selectedFile) {
            formData.append('payment_file', selectedFile);
          }
  
          const token = localStorage.getItem('token');
          await axios.post(
            `${API_URL}/payment`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
  
          notification.success({
            message: t('Payment Confirmed'),
            description: t('การชำระเงินได้รับการยืนยันแล้ว!'),
          });
  
          clearModalForm(); // Clear all form data
          setIsModalOpen(false);
          fetchTransactions(); // Refresh the main table
          
          // Refresh the active tab
          handleTabRefresh(activeKey);
  
        } catch (error) {
          console.error('Error logging payment:', error);
          notification.error({
            message: t('error'),
            description: t('payment_log_error'),
          });
        }
      },
    });
  };
  
  // Open NPL modal
  const openNPLModal = (transaction) => {
    setSelectedTransaction(transaction); // Set the selected transaction
    setIsNPLModalOpen(true); // Open the modal
  };

  // Handle confirming NPL
  const handleMarkAsNPL = async (transaction) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/payment/mark-npl`,
        { transaction_id: transaction.transaction_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      notification.success({
        message: t('npl_success'),
        description: t('transaction_marked_as_npl'),
      });
      fetchTransactions(); // Refresh the transaction list
      setIsNPLModalOpen(false); // Close the modal
    } catch (error) {
      notification.error({
        message: t('npl_error'),
        description: t('transaction_marked_as_npl_failed'),
      });
    }
  };

  // Close NPL modal
  const handleNPLModalCancel = () => {
    setIsNPLModalOpen(false);
  };


  const handleModalCancel = () => {
    clearModalForm(); // Call clearModalForm first
    setIsModalOpen(false);
  };

  

  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
  };

  
  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
  
    // Format date as dd-MM-yyyy HH:mm
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Ensure 24-hour format
    }).format(date);
  
    return formattedDate;
  };
  
  const csvData = filteredTransactions.map((transaction) => {
    let translatedPaymentMethod = transaction.payment_method;
  
    switch (transaction.payment_method) {
      case 'cash':
        translatedPaymentMethod = t('cash');
        break;
      case 'paymentplan':
        translatedPaymentMethod = t('paymentplan');
        break;
      case 'scholarship':
        translatedPaymentMethod = t('scholarship');
        break;
      case 'credit card':
        translatedPaymentMethod = t('credit_card');
        break;
      case 'qr code':
        translatedPaymentMethod = t('qr_code');
        break;
      case 'transfer':
        translatedPaymentMethod = t('transfer');
        break;
      default:
        translatedPaymentMethod = transaction.payment_method;
    }
  
    return {
      document_no: transaction.document_no,
      transaction_date: formatDateTime(transaction.transaction_date),
      nationalid: transaction.nationalid,
      student_id: transaction.schoolid,
      student_name: transaction.student_name,
      phone: transaction.phone,
      address: transaction.address,
      payment_method: translatedPaymentMethod,
      final_amount: transaction.final_amount,
      remaining_balance: transaction.remaining_amount,
      items: transaction.items ? transaction.items.map(item => item.item_name).join(', ') : '',
      npl_status: transaction.npl ? t('หนี้เสีย') : t('ปกติ'), // Add translated NPL status
    };
  });
  

  const rowClassName = (record) => {
    return record.npl ? 'npl-row' : '';
  };

  const currentDate = moment().format('DD-MM-YYYY');
const fileName = `รายการลูกหนี้_${currentDate}.csv`;
  
const columns = [
  {
    title: t('document_no'),
    dataIndex: 'document_no',
    key: 'document_no',
  },
  {
    title: t('transaction_date'),
    dataIndex: 'transaction_date',
    key: 'transaction_date',
    ellipsis: true,
    render: (date) => {
      if (!date) return 'N/A'; // Handle missing dates

      // Format the date based on the user's locale
      return new Intl.DateTimeFormat(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }).format(new Date(date));
    },
  },
  {
    title: t('nationalid'),
    dataIndex: 'nationalid',
    key: 'nationalid',
  },
  {
    title: t('student_id'),
    dataIndex: 'schoolid',
    key: 'schoolid',
  },
  {
    title: t('student_name'),
    dataIndex: 'student_name',
    key: 'student_name',
    ellipsis: true,
  },
  {
    title: t('phone'),
    dataIndex: 'phone',
    key: 'phone',
  },

  {
    title: t('items'),
    key: 'items',
    render: (record) => {
      if (!record.items || record.items.length === 0) {
        return t('No items');
      }
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {record.items.map((item, index) => (
            <Tooltip key={index} title={item.item_name}>
              <div style={{ 
                padding: '4px 0',
                borderBottom: index < record.items.length - 1 ? '1px solid #f0f0f0' : 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}>
                {item.item_name}
              </div>
            </Tooltip>
          ))}
        </div>
      );
    },
  },
  {
    title: t('payment_method'),
    dataIndex: 'payment_method',
    key: 'payment_method',
    ellipsis: true,
    render: (text) => {
      switch (text) {
        case 'fullpay':
          return t('fullpay');
        case 'paymentplan':
          return t('paymentplan');
        case 'scholarship':
          return t('scholarship');
        default:
          return text; 
      }
    },
  },
  {
    title: t('final_amount'),
    dataIndex: 'final_amount',
    key: 'final_amount',
    render: (amount) => formatPrice(amount), // Use formatPrice
  },
  {
    title: t('remaining_balance'),
    dataIndex: 'remaining_amount',
    key: 'remaining_amount',
    render: (amount) => (
      <span style={{ color: 'red', fontWeight: 'bold' }}>
        {formatPrice(amount)}
      </span>
    ),
  },
 
  {
    title: t('action'),
    key: 'action',
    ellipsis: true,
    render: (_, record) => (
      <>
        <Button
          type="primary"
          onClick={() => openModal(record)}
          disabled={record.npl}
        >
          {t('pay')}
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => openInvoiceModal(record)}
          disabled={record.npl}
        >
          {t('invoice')}
        </Button>
        <Button
          className="delete-button"
          type="danger"
          onClick={() => openNPLModal(record)}
          disabled={record.npl}
          style={
            record.npl
              ? {
                  backgroundColor: '#d9d9d9',
                  borderColor: '#d9d9d9',
                  color: '#999',
                  marginLeft: 10,
                }
              : { marginLeft: 10 }
          }
        >
          {t('npl')}
        </Button>
      </>
    ),
  },
];


  return (
    <div className="payment-page-container">
      <Sidebar />
      <Header />
      <h1>{t('payment')}</h1>

      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab={t('Account receivable')} key="1">
        <div className="filters-container" style={{ marginBottom: '20px' }}>
        <DatePicker
          selected={filters.fromDate}
          onChange={(date) => handleDateChange(date, 'fromDate')}
          dateFormat="dd-MM-yyyy"
          locale={i18n.language === 'th' ? 'th' : 'en'}
          placeholderText={t('startDate')}
          style={{ marginRight: '10px' }}
        />
        <DatePicker
          selected={filters.toDate}
          onChange={(date) => handleDateChange(date, 'toDate')}
          dateFormat="dd-MM-yyyy"
          locale={i18n.language === 'th' ? 'th' : 'en'}
          placeholderText={t('endDate')}
          style={{ marginRight: '10px' }}
        />
            <input
              type="text"
              name="searchTerm"
              className='ant-input'
              value={filters.searchTerm}
              onChange={handleFilterChange}
              placeholder={t('searchByNameOrNationalID')}
              style={{ width: 250, marginRight: '10px' }}
            />
            <input
              type="text"
              name="documentNo"
              className='ant-input'
              value={filters.documentNo}
              onChange={handleFilterChange}
              placeholder={t('searchByDocumentNo')}
              style={{ width: 150, marginRight: '10px' }}
            />
            <select
              name="paymentMethod"
              className='ant-input'
              value={filters.paymentMethod}
              onChange={handleFilterChange}
              style={{ width: 150, marginRight: '10px' }}
            >
              <option value="">{t('paymentMethod')}</option>
              <option value="fullpay">{t('fullpay')}</option>
              <option value="paymentplan">{t('paymentplan')}</option>
              <option value="scholarship">{t('scholarship')}</option>
            </select>
            <select
              name="nplStatus"
              className='ant-input'
              value={filters.nplStatus}
              onChange={handleFilterChange}
              style={{ width: 150, marginRight: '10px' }}
            >
              <option value="">{t('หนี้เสีย')}</option>
              <option value="NPL">{t('yes')}</option>
              <option value="non-NPL">{t('no')}</option>
            </select>
            <CSVLink 
              data={csvData} 
              filename={fileName}
              className="ant-btn"
              style={{ marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}
            >
              <FontAwesomeIcon icon={faFileExcel}/>
              {t('exportToExcel')}
            </CSVLink>
          </div>
          <Table
            columns={columns}
            dataSource={filteredTransactions}
            loading={loading}
            rowKey="transaction_id"
            rowClassName={rowClassName}
            scroll={{ x: true }}
            pagination={{
              pageSize: 50,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />

      <Modal
      title={t('create_payment')}
      visible={isModalOpen}
      onOk={handleModalOk}
      onCancel={() => setIsModalOpen(false)}
      afterClose={clearModalForm}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('payment_amount')}
          name="payment_amount"
          rules={[{ required: true, message: t('please_enter_payment_amount') }]}
        >
          <Input placeholder={t('enter_payment_amount')} />
        </Form.Item>
        <Form.Item
          label={t('payment_method')}
          name="payment_method"
          rules={[{ required: true, message: t('please_select_payment_method') }]}
        >
          <Select placeholder={t('select_payment_method')}>
            <Option value="cash">{t('cash')}</Option>
            <Option value="credit card">{t('credit_card')}</Option>
            <Option value="qr code">{t('qr_code')}</Option>
            <Option value="transfer">{t('transfer')}</Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('memo')} name="memo">
          <Input.TextArea rows={3} placeholder={t('enter_memo')} />
        </Form.Item>
        <Form.Item label={t('signature')}>
          <SignaturePad
            ref={signaturePadRef}
            canvasProps={{ width: 500, height: 200, className: 'signature-pad' }}
          />
          <Button onClick={() => signaturePadRef.current.clear()} style={{ marginTop: '10px' }}>
            {t('clear_signature')}
          </Button>
        </Form.Item>
        <Form.Item label={t('upload_payment_proof')}>
          <input
            type="file"
            accept="application/pdf,image/jpeg"
            onChange={handleFileChange}
            ref={fileInputRef} // Attach the ref to the file input
          />
          {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
        </Form.Item>
      </Form>
    </Modal>


          {/* NPL Confirmation Modal */}
      <NPLConfirmationModal
        visible={isNPLModalOpen}
        transaction={selectedTransaction}
        onConfirm={handleMarkAsNPL}
        onCancel={handleNPLModalCancel}
      />


          {/* Invoice Modal */}
          <InvoiceModal
            show={isInvoiceModalOpen}
            onClose={handleInvoiceModalClose}
            registration={selectedTransaction}
          />
        </TabPane>

        <TabPane tab={t('income')} key="2">
          <PaymentReportPage 
            key={`payment-report-${shouldRefreshTabs['2']}`} 
            onLoad={() => setShouldRefreshTabs(prev => ({ ...prev, '2': false }))}
          />
        </TabPane>
        <TabPane tab={t('studentFullypaid')} key="3">
          <PaymentSummaryPage 
            key={`payment-summary-${shouldRefreshTabs['3']}`}
            onLoad={() => setShouldRefreshTabs(prev => ({ ...prev, '3': false }))}
          />
        </TabPane>
        <TabPane tab={t('scholarshipPayment')} key="4">
          <ScholarshipPayment 
            key={`scholarship-${shouldRefreshTabs['4']}`}
            onLoad={() => setShouldRefreshTabs(prev => ({ ...prev, '4': false }))}
          />
        </TabPane>
        <TabPane tab={t('paymentPlanPayment')} key="5">
          <PaymentPlanPayment 
            key={`payment-plan-${shouldRefreshTabs['5']}`}
            onLoad={() => setShouldRefreshTabs(prev => ({ ...prev, '5': false }))}
          />
        </TabPane>
      </Tabs>

      
    </div>
  );
};

export default withRoleAccess(PaymentPage, ['superadmin', 'admin']);
