import React, { useState, useEffect, useCallback } from 'react';
import withRoleAccess from '../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { Card, Row, Col, Spin, notification, Tabs, Statistic, Table, Select, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';

const { TabPane } = Tabs;
const { Option } = Select;

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [salesSummary, setSalesSummary] = useState([]);
  const [incomeSummary, setIncomeSummary] = useState([]);
  const [inventorySummary, setInventorySummary] = useState([]);
  const [loadingSalesIncome, setLoadingSalesIncome] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [expenseSummary, setExpenseSummary] = useState([]);
  const [loadingExpense, setLoadingExpense] = useState(false);
  const [selectedInventoryYear, setSelectedInventoryYear] = useState(moment().year());
  const [selectedInventoryMonth, setSelectedInventoryMonth] = useState(moment().month() + 1);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchSalesIncomeData = useCallback(async () => {
    setLoadingSalesIncome(true);
    try {
      const token = localStorage.getItem('token');
      const [responseSales, responseIncome] = await Promise.all([
        axios.get(`${API_URL}/report/sales-summary`, {
          params: { year: moment().year() },
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get(`${API_URL}/report/income-summary`, {
          params: { year: moment().year() },
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      setSalesSummary(processMonthlySummary(responseSales.data));
      setIncomeSummary(processMonthlySummary(responseIncome.data));
    } catch (error) {
      console.error('Error fetching data:', error);
      notification.error({
        message: 'Error fetching data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoadingSalesIncome(false);
    }
  }, [API_URL]);

  const fetchInventoryData = useCallback(async () => {
    setLoadingInventory(true);
    try {
      const token = localStorage.getItem('token');
      const responseInventory = await axios.get(`${API_URL}/report/inventory-summary`, {
        params: { year: selectedInventoryYear },
        headers: { Authorization: `Bearer ${token}` }
      });
      const filteredData = responseInventory.data.filter(item => 
        parseInt(item.month, 10) === selectedInventoryMonth
      );
      setInventorySummary(processInventoryData(filteredData));
    } catch (error) {
      console.error('Error fetching inventory data:', error);
      notification.error({
        message: 'Error fetching inventory data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoadingInventory(false);
    }
  }, [API_URL, selectedInventoryYear, selectedInventoryMonth]);

  useEffect(() => {
    fetchSalesIncomeData();
  }, [fetchSalesIncomeData]);

  useEffect(() => {
    fetchInventoryData();
  }, [fetchInventoryData]);

  const processMonthlySummary = (data) => {
    const monthlySummary = data.reduce((acc, item) => {
      const monthKey = `${item.year}-${item.month}`;
      if (!acc[monthKey]) {
        acc[monthKey] = {
          year: item.year,
          month: item.month,
          total: 0,
          count: 0,
        };
      }
      acc[monthKey].total += parseFloat(item.total_sales || item.total_income);
      acc[monthKey].count += parseInt(item.transaction_count || item.payment_count);
      return acc;
    }, {});
    return Object.values(monthlySummary).sort((a, b) => (a.year === b.year ? a.month - b.month : a.year - b.year));
  };

  const processInventoryData = (data) => {
    const grouped = data.reduce((acc, item) => {
      if (!acc[item.inventory_id]) {
        acc[item.inventory_id] = {
          item_name: item.item_name,
          category: item.category,
          total_quantity: 0,
          total_sales: 0,
        };
      }
      const quantity = parseInt(item.total_quantity, 10);  // Use total_quantity from the API
      acc[item.inventory_id].total_quantity += !isNaN(quantity) ? quantity : 0;
      acc[item.inventory_id].total_sales += parseFloat(item.total_price || 0);  // Use total_price from the API
      return acc;
    }, {});
    return Object.values(grouped);
  };
  

  const calculateTotals = (data) => {
    const currentMonth = moment().month() + 1;
    const currentDate = moment().date();
    const currentYear = moment().year();

    const ytd = data.reduce((sum, item) => sum + item.total, 0);
    const mtd = data
      .filter(item => item.month === currentMonth && item.year === currentYear)
      .reduce((sum, item) => sum + item.total, 0);
    const today = data
      .filter(item => item.year === currentYear && item.month === currentMonth && item.day === currentDate)
      .reduce((sum, item) => sum + item.total, 0);
    return { ytd, mtd, today };
  };
  const fetchExpenseData = useCallback(async () => {
    setLoadingExpense(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/report/expense-summary`, {
        params: { year: moment().year() },
        headers: { Authorization: `Bearer ${token}` }
      });
      setExpenseSummary(response.data);
    } catch (error) {
      console.error('Error fetching expense data:', error);
      notification.error({
        message: 'Error fetching expense data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoadingExpense(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchExpenseData();
  }, [fetchExpenseData]);



  const formatCurrency = (value) => {
    return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(value);
  };

  const handleYearChange = (value) => {
    setSelectedInventoryYear(value);
  };

  const handleMonthChange = (value) => {
    setSelectedInventoryMonth(value);
  };

  const salesColumns = [
    {
      title: t('month'),
      dataIndex: 'month',
      key: 'month',
      render: (month) => moment().month(month - 1).format('MMMM'),
    },
    {
      title: t('Total_sales'),
      dataIndex: 'total',
      key: 'total',
      render: (value) => formatCurrency(value),
    },
    {
      title: t('Transaction Count'),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  const incomeColumns = [
    {
      title: t('month'),
      dataIndex: 'month',
      key: 'month',
      render: (month) => moment().month(month - 1).format('MMMM'),
    },
    {
      title: t('income'),
      dataIndex: 'total',
      key: 'total',
      render: (value) => formatCurrency(value),
    },
    {
      title: t('Transaction Count'),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  const inventoryColumns = [
    {
      title: t('Item Name'),
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('Total Quantity Sold'),
      dataIndex: 'total_quantity',
      key: 'total_quantity',
    },
    {
      title: t('Total_sales'),
      dataIndex: 'total_sales',
      key: 'total_sales',
      render: (value) => formatCurrency(value),
    },
  ];

  const expenseColumns = [
    {
      title: t('month'),
      dataIndex: 'month_year',
      key: 'month_year',
      render: (monthYear) => moment(monthYear).format('MMMM YYYY'),
    },
    {
      title: t('total_expenses_by_month'),
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (value) => formatCurrency(value),
    },
  ];

  const salesTotals = calculateTotals(salesSummary);
  const incomeTotals = calculateTotals(incomeSummary);

  const ExportButton = ({ data, filename }) => (
    <CSVLink data={data} filename={filename}>
      <Button icon={<DownloadOutlined />} style={{ marginBottom: 16 }}>
        Export to Excel
      </Button>
    </CSVLink>
  );


  return (
    <div className="reports-container">
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t('sales_report')} key="1">
            <Spin spinning={loadingSalesIncome}>
            <ExportButton 
              data={salesSummary.map(item => ({
                Month: moment().month(item.month - 1).format('MMMM'),
                'Total Sales': item.total,
                'Transaction Count': item.count
              }))} 
              filename="sales_summary.csv" 
            />
              <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col span={6}>
                  <Card>
                    <Statistic title={t('year_to_date_sales')} value={formatCurrency(salesTotals.ytd)} />
                  </Card>
                </Col>
              </Row>
              <Table 
                dataSource={salesSummary} 
                columns={salesColumns}
                pagination={false}
                summary={(pageData) => {
                  const totalSales = pageData.reduce((sum, item) => sum + Number(item.total), 0);
                  const totalTransactions = pageData.reduce((sum, item) => sum + Number(item.count), 0);
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                      <Table.Summary.Cell>{formatCurrency(totalSales)}</Table.Summary.Cell>
                      <Table.Summary.Cell>{totalTransactions}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </Spin>
          </TabPane>
  
          <TabPane tab={t('Income Summary')} key="2">
            <Spin spinning={loadingSalesIncome}>
            <ExportButton 
              data={incomeSummary.map(item => ({
                Month: moment().month(item.month - 1).format('MMMM'),
                'Total Income': item.total,
                'Payment Count': item.count
              }))} 
              filename="income_summary.csv" 
            />
              <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col span={6}>
                  <Card>
                    <Statistic title={t('year_to_date_income')} value={formatCurrency(incomeTotals.ytd)} />
                  </Card>
                </Col>
              </Row>
              <Table 
                dataSource={incomeSummary} 
                columns={incomeColumns}
                pagination={false}
                summary={(pageData) => {
                  const totalIncome = pageData.reduce((sum, item) => sum + Number(item.total), 0);
                  const totalPayments = pageData.reduce((sum, item) => sum + Number(item.count), 0);
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                      <Table.Summary.Cell>{formatCurrency(totalIncome)}</Table.Summary.Cell>
                      <Table.Summary.Cell>{totalPayments}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </Spin>
          </TabPane>
  
          <TabPane tab={t('Inventory Summary')} key="3">
            <Row gutter={16} style={{ marginBottom: '20px' }}>
              <Col span={12}>
                <Select value={selectedInventoryYear} onChange={handleYearChange} style={{ width: '100%' }}>
                  {[...Array(5)].map((_, i) => {
                    const year = moment().subtract(i, 'years').year();
                    return <Option key={year} value={year}>{year}</Option>;
                  })}
                </Select>
              </Col>
              <Col span={12}>
                <Select value={selectedInventoryMonth} onChange={handleMonthChange} style={{ width: '100%' }}>
                  {[...Array(12)].map((_, i) => (
                    <Option key={i + 1} value={i + 1}>
                      {moment().month(i).format('MMMM')}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Spin spinning={loadingInventory}>
            <ExportButton 
              data={inventorySummary.map(item => ({
                'Item Name': item.item_name,
                Category: item.category,
                'Total Quantity Sold': item.total_quantity,
                'Total Sales': item.total_sales
              }))} 
              filename="inventory_summary.csv" 
            />
              <Table 
                dataSource={inventorySummary} 
                columns={inventoryColumns}
                pagination={{ pageSize: 50 }}
                scroll={{ y: 400 }}
                summary={(pageData) => {
                  const totalQuantity = pageData.reduce((sum, item) => sum + Number(item.total_quantity), 0);
                  const totalSales = pageData.reduce((sum, item) => sum + Number(item.total_sales), 0);
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>{totalQuantity}</Table.Summary.Cell>
                      <Table.Summary.Cell>{formatCurrency(totalSales)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </Spin>
          </TabPane>

          <TabPane tab={t('expense_report')} key="4">
            <Spin spinning={loadingExpense}>
              <ExportButton 
                data={expenseSummary.map(item => ({
                  Month: moment(item.month_year).format('MMMM YYYY'),
                  'Total Expense': item.total_amount,
                  'Transaction Count': item.transaction_count
                }))} 
                filename="expense_summary.csv" 
              />
              <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col span={6}>
                  <Card>
                    <Statistic 
                      title={t('year_to_date_expenses')} 
                      value={formatCurrency(expenseSummary.reduce((sum, item) => sum + Number(item.total_amount), 0))} 
                    />
                  </Card>
                </Col>
              </Row>
              <Table 
                dataSource={expenseSummary} 
                columns={expenseColumns}
                pagination={false}
                summary={(pageData) => {
                  const totalExpense = pageData.reduce((sum, item) => sum + Number(item.total_amount), 0);
                  const totalTransactions = pageData.reduce((sum, item) => sum + Number(item.transaction_count), 0);
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                      <Table.Summary.Cell>{formatCurrency(totalExpense)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </Spin>
          </TabPane>

        </Tabs>
      </div>
    </div>
  );
};

export default withRoleAccess(AdminDashboard, ['superadmin']);