import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Table, Spin, notification, Input, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/th';
import './GraduateGroup.css';

const { Search } = Input;
const { Option } = Select;

const GroupList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [graduationData, setGraduationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  moment.locale('th');

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (!a.start_date && !b.start_date) return 0;
      if (!a.start_date) return 1;
      if (!b.start_date) return -1;
      return moment(b.start_date).valueOf() - moment(a.start_date).valueOf();
    });
  }, [filteredData]);

  useEffect(() => {
    const fetchStudentGraduationDetails = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/graduate-group/fetch-student-graduation-details`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setGraduationData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        notification.error({ message: t('Error fetching student graduation details') });
      } finally {
        setLoading(false);
      }
    };

    fetchStudentGraduationDetails();
  }, [API_URL, t]);

  const uniqueCourses = useMemo(() => {
    return ['', ...new Set(graduationData.map(item => item.course_name))];
  }, [graduationData]);

  const availableGroups = useMemo(() => {
    if (!selectedCourse) return [''];
    return ['', ...new Set(graduationData
      .filter(item => item.course_name === selectedCourse)
      .map(item => item.group_name))];
  }, [graduationData, selectedCourse]);

  const applyFilters = () => {
    let result = [...graduationData];

    if (selectedCourse) {
      result = result.filter(item => item.course_name === selectedCourse);
    }

    if (selectedGroup) {
      result = result.filter(item => item.group_name === selectedGroup);
    }

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      result = result.filter(item =>
        item.firstnamethai.toLowerCase().includes(lowerSearchTerm) ||
        item.lastnamethai.toLowerCase().includes(lowerSearchTerm) ||
        item.firstnameenglish.toLowerCase().includes(lowerSearchTerm) ||
        item.lastnameenglish.toLowerCase().includes(lowerSearchTerm) ||
        item.nationalid.includes(lowerSearchTerm)
      );
    }

    setFilteredData(result);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedCourse, selectedGroup, searchTerm]);

  const handleCourseChange = (value) => {
    setSelectedCourse(value);
    setSelectedGroup('');
  };

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  

  // Reset function to clear all filters
  const handleResetFilters = () => {
    setSelectedCourse('');
    setSelectedGroup('');
    setSearchTerm('');
    setFilteredData(graduationData);  // Reset filtered data to original
  };

  const formatThaiDate = (date) => {
    if (!date) return 'N/A';
    const momentDate = moment(date);
    const day = momentDate.format('DD');
    const thaiMonth = momentDate.format('MMMM');
    const buddhistYear = parseInt(momentDate.format('YYYY')) + 543;
    return `${day}-${thaiMonth}-${buddhistYear}`;
  };

  const prefixTranslations = {
    'Miss': 'นางสาว',
    'Mr': 'นาย',
    'Mrs': 'นาง'
};
  
  const educationLevels = {
    below_highschool: t('below_highschool'),
    highschool: t('highschool'),
    below_bachelor_degree: t('below_bachelor_degree'),
    bachelor_degree: t('bachelor_degree'),
    above_bachelor_degree: t('above_bachelor_degree'),
  };

  const csvData = filteredData.map(item => ({
    schoolid: item.schoolid,
    course_name: item.course_name,
    group_name: item.group_name,
    start_date: formatThaiDate(item.start_date),
    end_date: formatThaiDate(item.end_date),
    thaiName: `${prefixTranslations[item.prefix] || item.prefix} ${item.firstnamethai} ${item.lastnamethai}`,
    englishName: `${item.prefix} ${item.firstnameenglish} ${item.lastnameenglish}`,
    nickname: item.nickname,
    nationalid: item.nationalid,
    nationality: item.nationality,
    race: item.race,
    phone: item.phone,
    email: item.email,
    address: item.address,
    dob: formatThaiDate(item.date_of_birth),
    education: educationLevels[item.education] || t('unknown'),
    massage_education: [item.massage_education]
}));

  const columns = [
    {
      title: t('schoolid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('Group and Course'),
      key: 'group_course',
      render: (record) => `(รุ่น ${record.group_name}) - ${record.course_name}`,
      ellipsis: true,
    },
    {
      title: t('start_date'),
      dataIndex: 'start_date',
      key: 'start_date',
      ellipsis: true,
      render: (date) => {
        if (!date) return t('N/A');
        return new Date(date).toLocaleDateString('th-TH', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
      },
    },
    {
      title: t('end_date'),
      dataIndex: 'end_date',
      key: 'end_date',
      ellipsis: true,
      render: (date) => {
        if (!date) return t('N/A');
        
        return new Date(date).toLocaleDateString('th-TH', {
          day: '2-digit',
          month: 'short', // Full month name
          year: 'numeric',
        });
      },
    },
    {
      title: t('first_name_thai'),
      key: 'thaiName',
      render: (record) => `${prefixTranslations[record.prefix] || record.prefix} ${record.firstnamethai} ${record.lastnamethai}`,
      ellipsis: true,
    },
    {
      title: t('first_name_english'),
      key: 'englishName',
      render: (record) => `${record.prefix} ${record.firstnameenglish} ${record.lastnameenglish}`,
      ellipsis: true,
    },
    {
      title: t('nickname'),
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
      ellipsis: true,
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('address'),
      dataIndex: 'address',
      key: 'address',
      render: (text) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxHeight: '3em', overflow: 'hidden' }}>
          {text}
        </div>
      ),
    },
    {
      title: t('dob'),
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      ellipsis: true,
      render: (date) => {
        if (!date) return t('N/A');
        
        return new Date(date).toLocaleDateString('th-TH', {
          day: '2-digit',
          month: 'short', // Full month name
          year: 'numeric',
        });
      },
    },
    
  ];



  return (
    <div className="student-graduation-details-page">
      <h1>{t('Student Graduation Details')}</h1>
      
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
        <Select
          value={selectedCourse}
          onChange={handleCourseChange}
          style={{ width: 280, height: '40px', lineHeight: '40px' }}
          placeholder={t('Select Course')}

        >
          {uniqueCourses.map(course => (
            <Option key={course} value={course}>{course || t('All Courses')}</Option>
          ))}
        </Select>

        <Select
          value={selectedGroup}
          onChange={handleGroupChange}
          style={{ width: 280, height: '40px', lineHeight: '40px' }}
          placeholder={t('Select Group')}
          disabled={!selectedCourse}
        >
          {availableGroups.map(group => (
            <Option key={group} value={group}>{group || t('All Groups')}</Option>
          ))}
        </Select>

        <Search
          placeholder={t('searchByNameOrNationalID')}
          onSearch={handleSearch}
          enterButton
          style={{ width: 300 }}
        />

        <Button onClick={handleResetFilters}>
          {t('Reset Filters')}
        </Button>

        <Button>
          <CSVLink data={csvData} filename="student_graduation_details.csv">
            <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
          </CSVLink>
        </Button>
      </div>

      {loading ? (
        <Spin size="large" />
      ) : (
      <Table
        dataSource={sortedData}
        columns={columns}
        rowKey={(record) => `${record.studentid}-${record.course_name}-${record.group_name}`}
        scroll={{ x: true }}
        pagination={{
          pageSize: 50,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      )}
    </div>
  );
};

export default withRoleAccess(GroupList, ['admin', 'superadmin']);